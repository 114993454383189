export enum ToolType {
  ColorAdjustment,
  ColorAnnotation,
  EllipticalAnnotation,
  Hand,
  LassoAnnotation,
  MagneticAnnotation,
  ObjectAnnotation,
  PenAnnotation,
  Pointer,
  PolygonalAnnotation,
  QuickAnnotation,
  RectangularAnnotation,
  Zoom,
  ThresholdAnnotation,
}
