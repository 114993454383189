export enum LanguageType {
  Arabic = "Arabic",
  English = "English",
  Farsi = "Farsi",
  Finnish = "Finnish",
  French = "French",
  German = "German",
  Greek = "Greek",
  Hindi = "Hindi",
  Hungarian = "Hungarian",
  Spanish = "Spanish",
}
